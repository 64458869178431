<template>
  <div>
    <div style="margin-bottom:15px;display:flex;justify-content:space-between;align-items:center;">
      <label class="label">Add FAQ to your page</label>
    </div>
      <div
      style="display: flex; justify-content: space-between; align-items: center;margin-top:15px;"
    >
     <label class="label">Show horizontal menu</label>
      <v-switch v-model="content.horizontal" @change="setMenu"></v-switch>
    </div>
    <div v-if="content.horizontal">
      <div
        style="
          display: flex;
          align-items: center;
          margin: 10px 0;
          justify-content: space-between;
        "
      >
        <label
          class="label"
          style="width: 250px; margin-bottom: 0; margin-right: 15px"
          >Background color</label
        >
        <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_payment_bg"
        >
          <template v-slot:activator="{ on }">
            <div
              class="colorpicker"
              :style="`background-color:${content.horizontal_background}`"
              style="margin-right: 10px"
              v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="content.horizontal_background"
                @input="show_payment_bg = false"
                flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          margin: 10px 0;
          justify-content: space-between;
        "
      >
        <label
          class="label"
          style="width: 250px; margin-bottom: 0; margin-right: 15px"
          >Background active color</label
        >
        <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_payment_bg_active"
        >
          <template v-slot:activator="{ on }">
            <div
              class="colorpicker"
              style="margin-right: 10px"
              :style="
                `background-color:${content.horizontal_active_background}`
              "
              v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="content.horizontal_active_background"
                @input="show_payment_bg_active = false"
                flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          margin: 10px 0;
          justify-content: space-between;
        "
      >
        <label
          class="label"
          style="width: 250px; margin-bottom: 0; margin-right: 15px"
          >Text color</label
        >
        <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_payment_color"
        >
          <template v-slot:activator="{ on }">
            <div
              class="colorpicker"
              style="margin-right: 10px"
              :style="`background-color:${content.horizontal_color}`"
              v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="content.horizontal_color"
                @input="show_payment_color = false"
                flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          margin: 10px 0;
          justify-content: space-between;
        "
      >
        <label
          class="label"
          style="width: 250px; margin-bottom: 0; margin-right: 15px"
          >Active text color</label
        >
        <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_payment_active_color"
        >
          <template v-slot:activator="{ on }">
            <div
              style="margin-right: 10px"
              class="colorpicker"
              :style="`background-color:${content.horizontal_active_color}`"
              v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="content.horizontal_active_color"
                @input="show_payment_active_color = false"
                flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <label class="label">Show motivation text</label>
      <v-switch v-model="content.motivation.show" @change="setMenu"></v-switch>
    </div>
    <div
      class="field"
      v-if="content.motivation.show"
      style="margin-bottom: 30px"
    >
      <label class="label">Change motivation text</label>
      <div class="control">
        <Editor
          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
          :init="options"
          v-model="content.motivation.content"
          @change="setMenu"
        ></Editor>
      </div>
    </div>
    <div
      class="control"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      "
    >
      <label class="label">Change active color</label>
      <v-menu
        top
        nudge-left="16"
        nudge-bottom="98"
        :close-on-content-click="false"
        v-model="show_faq_color"
      >
        <template v-slot:activator="{ on }">
          <div
            class="colorpicker"
            :style="`background-color:${content.color}`"
            style="margin-right: 10px"
            v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="content.color" @change="setMenu" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div
      class="control"
      v-for="(faq, faqGroupIndex) in content.content"
      :key="faqGroupIndex"
      style="
        margin-bottom: 20px;
        border: 1px solid #eee;
        padding: 15px;
        border-radius: 5px;
      "
    >
      <v-btn
        class="listing__delete"
        depressed
        small
        icon
        style="top: 0;float:right;"
        @click="openFaqGroupDelete(faqGroupIndex)"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
      <label class="label">FAQ group title</label>
      <v-text-field
        style="margin-top: 15px"
        outlined
        solo
        flat
        dense
        v-model="faq.title"
      ></v-text-field>
      <v-data-table
        :headers="[
          {
            text: 'Question',
            value: 'question',
          },
          {
            align: 'center',
            text: 'Actions',
            value: 'actions',
          },
        ]"
        :items="faq.content"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
                <v-icon
                  color="primary"
                  dark
                  class="mb-2"
                  large
                  @click="openAddDialog(faqGroupIndex)"
                >
                  mdi-plus
                </v-icon>
          </v-toolbar>
        </template>

        <template v-slot:body>
          <draggable v-model="faq.content" tag="tbody">
            <tr v-for="(faqItem,faqItemIndex) in faq.content" :key="faqItem.id">
              <td class="d-block d-sm-table-cell">{{ faqItem.question }}</td>
              <td class="d-block d-sm-table-cell" style="display: flex; justify-content: center">
                    <v-icon
                      small
                      class="mr-2"
                      @click="openEditItem(faqItem,faqGroupIndex,faqItemIndex)"
                    >
                      mdi-pencil
                    </v-icon>
                <v-icon small @click="openFaqDelete(faqGroupIndex,faqItemIndex)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </div>
    <v-btn
      style="margin-left: auto; display: flex"
      class="mb-4"
      color="primary"
      small
      outlined
      @click="addFaq(content.content)"
    >
      + Add FAQ Group
    </v-btn>
    <SliderMenu
      :slider_menu="content.slider"
      :active_slider="content"
      :menu="menu"
    />

      <PopupDelete
        ref="openFaqGroupDeleteRef"
        @confirm="deleteFaqGroup"
        :title="'Are you sure about delete?'"
      />
                <PopupDelete
                  ref="PopupDeleteFaq"
                  @confirm="deleteFaqItem()"
                  :title="'Are you sure about delete?'"
                />
                <v-dialog v-model="dialogEdit" max-width="700px" eager>
                  <v-card>
                    <v-icon
                      style="top: 10px; right: 10px; position: absolute"
                      class="close__modal"
                      @click="closeEditDialog"
                      >mdi-close</v-icon
                    >
                    <v-card-title style="margin-bottom: 10px">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <span class="text-h5">Edit Item</span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <label class="label">Question</label>
                            <v-text-field
                              v-model="editedItem.question"
                              hide-details
                              dense
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <label class="label">Answer</label>
                              <Editor v-if="showMce"
                                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                                      :init="options"
                                      v-model="editedItem.answer"
                              ></Editor>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="primary"
                        style="color: #fff; margin-bottom: 20px"
                        small
                        outlined
                        @click="saveEdit()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            <v-dialog v-model="dialogAdd" max-width="700px" eager>
              <v-card>
                <v-icon
                  style="top: 10px; right: 10px; position: absolute"
                  class="close__modal"
                  @click="closeAddDialog"
                  >mdi-close</v-icon
                >
                <v-card-title style="margin-bottom: 20px">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <span class="text-h5">New Item</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <label class="label">Question</label>
                        <v-text-field
                          v-model="addItemFaq.question"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <label class="label">Answer</label>
                          <Editor v-if="showMce"
                                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                                  :init="options"
                                  v-model="addItemFaq.answer"
                          ></Editor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    outlined
                    style="color: #fff; margin-bottom: 20px"
                    small
                    @click="saveFaq()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
  <PopupSuccess :title="'Thank you!'" :content="successMessage" ref="PopupSuccess"/>
  <PopupConfirm ref="PopupConfirm"  @confirm="confirmCopy" :title="`Are you sure about copy content from ${defaultLangName} to ${currentLangName}`"/>
  </div>
</template>

<script>

import draggable from "vuedraggable";
import PopupDelete from "../../Popup/PopupDelete";
import SliderMenu from "./SliderMenu";
import { mapState } from "vuex";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue"
import PopupConfirm from "@/components/Popup/PopupConfirm.vue"
export default {
  name: "typeFaq",
  props: ["item", "content"],
  components: {
    SliderMenu,
    PopupDelete,
    draggable,
    PopupSuccess,
    PopupConfirm
  },
  data() {
    return {
      showMce:false,
      successMessage:"",
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 400,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_rel',
                                  label: 'Rel',
                                  items: [
                                      {text: 'No value', value: ''},
                                      {text: 'Alternate', value: 'alternate'},
                                      {text: 'Help', value: 'help'},
                                      {text: 'Manifest', value: 'manifest'},
                                      {text: 'No follow', value: 'nofollow'},
                                      {text: 'No opener', value: 'noopener'},
                                      {text: 'No referrer', value: 'noreferrer'},
                                      {text: 'Opener', value: 'opener'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      editedItem: {
        question: "default question",
        answer: "default answer",
      },
      addItemFaq: {
        question: "default question",
        answer: "default answer",
      },
      editedIndex: -1,
      defaultItem: {
        question: "default question",
        answer: "default answer",
      },
      show_payment_active_color: false,
      show_payment_color: false,
      show_payment_bg_active: false,
      show_payment_bg: false,
      indexGroupFaq: null,
      arrGroupFaq: null,
      show_faq_color: false,
      dialogEdit:false,
      dialogAdd:false,
      faqGroupIndex:0,
      faqItemIndex:0
    };
  },
  methods: {
    confirmCopy(){
      this.item.content[this.landing.current_lang][5] = JSON.parse(JSON.stringify(this.item.content[this.landing.advanced.default_lang][5]));
      this.forceRerender();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    openCopyLang(){
      this.$refs.PopupConfirm.open();
    },
    openAddDialog(faqGroupIndex){
      this.faqGroupIndex = faqGroupIndex;
      this.dialogAdd = true;
    },
    closeAddDialog(){
      this.dialogAdd = false;
      this.addItemFaq.question = "default question";
      this.addItemFaq.answer = "default answer";
    },
    saveFaq() {
      let faq = this.content.content[this.faqGroupIndex];
      const item = {
        question: this.addItemFaq.question,
        answer: this.addItemFaq.answer,
        id: faq.content.length ? faq.content.length : 0,
        edit: false,
      };
      faq.content.push(item);
      this.dialogAdd = false;
      this.successMessage = "New faq added successfull";
      this.$refs.PopupSuccess.open();
      this.addItemFaq.question = "default question";
      this.addItemFaq.answer = "default answer";
    },
    openEditItem(faqItem,faqGroupIndex,faqItemIndex){
      this.faqGroupIndex = faqGroupIndex;
      this.faqItemIndex = faqItemIndex;
      this.editedItem = Object.assign({}, faqItem);
      this.dialogEdit = true;
    },
    closeEditDialog(){
      this.dialogEdit = false;
      this.faqGroupIndex = 0;
      this.faqItemIndex = 0;
      this.editedItem = {
        question: "default question",
        answer: "default answer",
      };
    },
    openFaqGroupDelete(index,) {
      this.faqGroupIndex = index;
      this.$refs.openFaqGroupDeleteRef.open();
    },
    deleteFaqGroup() {
      this.content.content.splice(this.faqGroupIndex, 1);
      this.faqGroupIndex = 0;
      this.successMessage = 'Faq group deleted successfull';
      this.$refs.PopupSuccess.open()
    },
    setMenu() {
      this.$store.commit("landing/setMenu", this.menu);
    },
    saveEdit() {
      let item = this.content.content[this.faqGroupIndex].content[this.faqItemIndex];
      item.answer = this.editedItem.answer;
      item.question = this.editedItem.question;
      this.dialogEdit = false;
      this.successMessage = 'Faq edited successfull';
      this.$refs.PopupSuccess.open()
      this.setMenu();
    },
    openFaqDelete(faqGroupIndex,faqItemIndex) {
      this.faqGroupIndex = faqGroupIndex;
      this.faqItemIndex = faqItemIndex;
      this.$refs.PopupDeleteFaq.open();
    },
    deleteFaqItem() {
      let content = this.content.content[this.faqGroupIndex].content
      content.splice(this.faqItemIndex, 1);
      this.faqGroupIndex = 0;
      this.faqItemIndex = 0;
      this.successMessage = 'Faq deleted successfull';
      this.$refs.PopupSuccess.open()
    },
    addFaq(content) {
      const defaultFaq = {
        title: "default group title",
        dialogFaqNew: false,
        content: [
          {
            question: "default question",
            answer: "default answer",
            id: 0,
            edit: false,
          },
        ],
      };
      content.push(defaultFaq);
      this.setMenu();
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["menu"]),
    currentLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.current_lang).name
    },
    defaultLangName(){
      return this.landing.advanced.langs.find(x => x.value === this.landing.advanced.default_lang).name
    },
  },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.showMce = true;
            }, 3000);
        });
    },
    beforeDestroy() {
        this.showMce = false;
    },
};
</script>

<style lang="scss" scoped></style>
